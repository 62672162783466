import { Link } from "gatsby";
import React from "react";
import { styled } from "../../stitches/stitches.config";
import Button from "./Button/Button";

const PageNotFound = () => {
  return (
    <Container>
      <Content>
        <Title>404</Title>
        <Link to="/">
          <Button size="large" tier="secondary" arrow>
            Back to Home
          </Button>
        </Link>
      </Content>
    </Container>
  );
};

export default PageNotFound;

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 797,
});

const Content = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

const Title = styled("h2", {
  fontFamily: "$inter",
  fontWeight: 700,
  fontSize: 101,
  letterSpacing: "-3%",
  marginBottom: 19,
});
