import React from "react";
import Layout from "../components/Layout";
import PageNotFound from "../components/shared/PageNotFound";

const seo = {
  title: "Page Not Found",
  description: "",
};

function PageNotFoundPage() {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <PageNotFound />
    </Layout>
  );
}

export default PageNotFoundPage;
